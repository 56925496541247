var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.remount)?_c('div',{staticClass:"tw-w-full tw-flex tw-flex-col"},[_c('home-navbar'),_c('section',{staticClass:"section-two tw-flex  lg:tw-flex-row tw-items-center"},[_c('div',{staticClass:"lg:tw-w-6/12 tw-w-full tw-flex tw-flex-col tw-mt-10 lg:tw-mt-0 ipad-section-one card-mobile"},[_c('v-col',{staticClass:"tw-flex tw-w-full tw-items-center tw-justify-between"},[_c('h6',{staticClass:"send-message"},[_vm._v("Send Message")]),_c('img',{staticStyle:{"height":"35px","width":"auto"},attrs:{"src":_vm.sendMail,"alt":"send message"}})]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.SendMessage)}}},[_c('div',{staticClass:"tw-flex tw-flex-col lg:tw-flex-row tw-w-full ipad"},[_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('label',{staticClass:"input-label"},[_vm._v("First Name")]),_c('validationProvider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"solo":"","hide-details":"","placeholder":"David"},model:{value:(_vm.messageData.firstname),callback:function ($$v) {_vm.$set(_vm.messageData, "firstname", $$v)},expression:"messageData.firstname"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('label',{staticClass:"input-label"},[_vm._v("Last Name")]),_c('validationProvider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"solo":"","hide-details":"","placeholder":"Ample"},model:{value:(_vm.messageData.lastname),callback:function ($$v) {_vm.$set(_vm.messageData, "lastname", $$v)},expression:"messageData.lastname"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('div',{staticClass:"tw-flex tw-flex-col lg:tw-flex-row  tw-w-full ipad"},[_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('label',{staticClass:"input-label"},[_vm._v("Email")]),_c('validationProvider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"solo":"","hide-details":"","placeholder":"david@example.com","type":"email"},model:{value:(_vm.messageData.email),callback:function ($$v) {_vm.$set(_vm.messageData, "email", $$v)},expression:"messageData.email"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1),_c('v-col',{attrs:{"sm":"12","lg":"6"}},[_c('label',{staticClass:"input-label"},[_vm._v("Phone Number")]),_c('validationProvider',{attrs:{"name":"Phone Number","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-text-field',{attrs:{"solo":"","hide-details":"","placeholder":"44 7721 580272","type":"tel"},model:{value:(_vm.messageData.phoneNumber),callback:function ($$v) {_vm.$set(_vm.messageData, "phoneNumber", $$v)},expression:"messageData.phoneNumber"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('div',{staticClass:"tw-flex tw-flex-col lg:tw-flex-row tw-w-full"},[_c('v-col',{attrs:{"sm":"12"}},[_c('label',{staticClass:"input-label"},[_vm._v("Message")]),_c('validationProvider',{attrs:{"name":"Message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{class:classes},[_c('v-textarea',{attrs:{"placeholder":"Hi","solo":"","hide-details":""},model:{value:(_vm.messageData.message),callback:function ($$v) {_vm.$set(_vm.messageData, "message", $$v)},expression:"messageData.message"}}),_c('span',[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)],1),_c('div',{staticClass:"tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-pt-5"},[_c('v-col',{attrs:{"sm":"12"}},[_c('v-btn',{staticClass:"send-btn",attrs:{"text":"","width":"100%","type":"submit","loading":_vm.messageLoading}},[_vm._v("Send")])],1)],1)])]}}],null,false,2257127509)})],1),_c('v-col',{staticClass:"lg:tw-w-4/12 tw-w-full tw-flex tw-flex-col lg:tw-ml-20 "},[_c('h6',{staticClass:"contact-header tw-mb-10"},[_vm._v("Contact Information")]),_c('div',{staticClass:"tw-flex tw-w-full tw-flex-row tw-py-5"},[_c('img',{staticClass:"tw-mr-5",staticStyle:{"width":"30px","height":"30px"},attrs:{"src":_vm.location,"alt":"location"}}),_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('p',{staticClass:"contact-detail"},[_vm._v("Contact Address")]),_c('p',{staticClass:"contact-detail text-capitalize"},[_vm._v("236a Kingston Road, Portsmouth, PO2 7LR")])])]),_c('div',{staticClass:"tw-flex tw-w-full tw-flex-row tw-py-5"},[_c('img',{staticClass:"tw-mr-5",staticStyle:{"width":"30px","height":"30px"},attrs:{"src":_vm.phoneCall,"alt":"location"}}),_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('p',{staticClass:"contact-detail"},[_vm._v("Phone Number")]),_c('p',{staticClass:"contact-detail"},[_vm._v("+44 7721 580272")])])]),_c('div',{staticClass:"tw-flex tw-w-full tw-flex-row tw-py-5"},[_c('img',{staticClass:"tw-mr-5",staticStyle:{"width":"30px","height":"30px"},attrs:{"src":_vm.emailDown,"alt":"location"}}),_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('p',{staticClass:"contact-detail"},[_vm._v("Email Address")]),_c('p',{staticClass:"contact-detail"},[_vm._v("hello@basketball4buckland.com")])])]),_c('social-media')],1)],1),_c('Footer')],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }