<template>
<div class="tw-w-full tw-flex tw-flex-col" v-if="remount">
<home-navbar />
  <section class="section-two tw-flex  lg:tw-flex-row tw-items-center">
      <div class="lg:tw-w-6/12 tw-w-full tw-flex tw-flex-col tw-mt-10 lg:tw-mt-0 ipad-section-one card-mobile">
          <v-col class="tw-flex tw-w-full tw-items-center tw-justify-between">
            <h6 class="send-message">Send Message</h6>
            <img :src="sendMail" alt="send message" style="height: 35px; width: auto" />
          </v-col>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(SendMessage)">
          <div class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full ipad">
            <v-col sm="12" lg="6">
              <label class="input-label">First Name</label>
              <validationProvider
                name="First Name"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div class="" :class="classes">
              <v-text-field solo hide-details v-model="messageData.firstname" placeholder="David"></v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
              </validationProvider>
            </v-col>
            <v-col sm="12" lg="6">
              <label class="input-label">Last Name</label>
              <validationProvider
                name="Last Name"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div class="" :class="classes">
              <v-text-field solo hide-details placeholder="Ample" v-model="messageData.lastname"></v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
              </validationProvider>
            </v-col>
          </div>
          <div class="tw-flex tw-flex-col lg:tw-flex-row  tw-w-full ipad">
            <v-col sm="12" lg="6">
              <label class="input-label">Email</label>
              <validationProvider
                name="Email"
                rules="required"
                v-slot="{ classes, errors }"
              >
                <div class="" :class="classes">
              <v-text-field solo hide-details placeholder="david@example.com" v-model="messageData.email" type="email"></v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
              </validationProvider>
            </v-col>
            <v-col sm="12" lg="6">
              <label class="input-label">Phone Number</label>
              <validationProvider
                name="Phone Number"
                rules="required"
                v-slot="{ classes, errors }">
                <div class="" :class="classes">
              <v-text-field solo hide-details placeholder="44 7721 580272" v-model="messageData.phoneNumber" type="tel"></v-text-field>
                  <span>{{ errors[0] }}</span>
                </div>
              </validationProvider>
            </v-col>
          </div>
          <div class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full">
            <v-col sm="12">
              <label class="input-label">Message</label>
              <validationProvider
                name="Message"
                rules="required"
                v-slot="{ classes, errors }">
                <div class="" :class="classes">
              <v-textarea placeholder="Hi" solo hide-details v-model="messageData.message" />
                  <span>{{ errors[0] }}</span>
                </div>
              </validationProvider>
            </v-col>
          </div>
          <div class="tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-pt-5">
            <v-col sm="12">
              <v-btn text width="100%" class="send-btn" type="submit" :loading="messageLoading">Send</v-btn>
            </v-col>
          </div>
          </form>
        </validation-observer>
      </div>
    <v-col class="lg:tw-w-4/12 tw-w-full tw-flex tw-flex-col lg:tw-ml-20 ">
       <h6 class="contact-header tw-mb-10">Contact Information</h6>
        <div class="tw-flex tw-w-full tw-flex-row tw-py-5">
          <img :src="location" alt="location" style="width: 30px; height: 30px" class="tw-mr-5">
          <div class="tw-flex tw-flex-col">
              <p class="contact-detail">Contact Address</p>
              <p class="contact-detail text-capitalize">236a Kingston Road, Portsmouth, PO2 7LR</p>
          </div>
        </div>
      <div class="tw-flex tw-w-full tw-flex-row tw-py-5">
        <img :src="phoneCall" alt="location" style="width: 30px; height: 30px" class="tw-mr-5">
        <div class="tw-flex tw-flex-col">
          <p class="contact-detail">Phone Number</p>
          <p class="contact-detail">+44 7721 580272</p>
        </div>
      </div>
      <div class="tw-flex tw-w-full tw-flex-row tw-py-5">
        <img :src="emailDown" alt="location" style="width: 30px; height: 30px" class="tw-mr-5">
        <div class="tw-flex tw-flex-col">
          <p class="contact-detail">Email Address</p>
          <p class="contact-detail">hello@basketball4buckland.com</p>
        </div>
      </div>
      <social-media/>
    </v-col>
  </section>
  <Footer/>
</div>
</template>

<script>
import HomeNavbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import contactMap from "@/assets/contactmap.svg"
import sendMail from "@/assets/sendmail.svg"
import location from "@/assets/location.svg"
import emailDown from "@/assets/email-down.svg"
import phoneCall from "@/assets/phone-call.svg"
import SocialMedia from "@/components/reuseables/SocialMedia";
import { contactUs } from "@/services/api/APIService";
export default {
  name: "ContactView",
  components: {SocialMedia, Footer, HomeNavbar},
  data(){
    return{
      contactMap,
      sendMail,
      location,
      emailDown,
      phoneCall,
      messageData:{},
      messageLoading : false,
      remount: true
    }
  },
  methods:{
    SendMessage(){
      this.messageLoading = true;
     contactUs(this.messageData).then(() =>{
       this.messageLoading = false;
       this.remount = false

       this.$displaySnackbar({
         message: "Thank you for reaching out, a representative will contact you!",
         success: true,
       });
       this.$nextTick(()=>{
         this.remount = true
       })

     })
       .catch(() => {
         this.messageLoading = false;
         this.$displaySnackbar({
           message: "Please Something went wrong try again",
           success: false,
         });

       });
    }
  }
}
</script>

<style scoped lang="scss">
.section-one{
  width: 100%;
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.section-two{
  width: 100%;
  min-height: 100vh;
  padding: 10rem 20rem;

  @media screen and (max-width: 1024px){
    padding: 5rem;
    flex-direction: row;
  }

  @media screen and (max-width: 768px){
    padding: 4rem 1.5rem;
    flex-direction: column-reverse;
  }
}
.send-message{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 42px;
  color: #F66B05;
}

.input-label{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgba(79, 79, 79, 0.5);
  padding-bottom: 10px;
}

.send-btn{
  background: #F66B05;
  box-shadow: 0 2px 10px rgba(130, 130, 130, 0.2);
  border-radius: 10px;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 35px;
  color: #FAFAFA;
  text-transform: none;
}

.contact-header{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 42px;
  color: rgba(0, 0, 0, 0.7);
}

.contact-detail{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  color: rgba(0, 0, 0, 0.5);
}

.ipad{
  @media screen and (max-width: 1024px) {
    flex-direction: column;
  }
}

.card-mobile{
  @media screen and (max-width: 1024px) {
    background: #FFFFFF;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.1);
    padding: 1rem;
  }
}
</style>